import { useState } from "react";
import { InputWrapper, Input } from "nystem-components";
import app from "nystem";

const TextIntInput = ({ model, setValue, value, className }) => {
  const { disabled, length, text } = model;
  const [id] = useState(app().uuid());

  const componentClassName = [
    className,
    ...(model.className || []),
    "textInput",
  ];

  return (
    <InputWrapper
      id={id}
      model={{ ...model, classNameInput: "flex", classNameLabel: " " }}
      className={componentClassName}
    >
      <Input
        id={id}
        placeholder={app().t(text)}
        className={model.classNameInput}
        value={value}
        maxLength={length}
        onChange={setValue}
        disabled={disabled}
        type="number"
        focus={model.focus || focus}
        selectAllOnFocus={model.selectAllOnFocus}
      />
    </InputWrapper>
  );
};
export default TextIntInput;
