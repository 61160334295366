import { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender, ContentTypeView } from "nystem-components";
import app from "nystem";

const ViewListRender = ({ model, view, value = [] }) => {
  const { rowClassName = [] } = model;

  function createItem(value) {
    console.log(value);
    const settings = {
      format: model.view,
      contentType: model.contentType,
      key: app().uuid(),
      noForm: true,
      baseView: view,
      params: view.params,
      className: rowClassName,
      value,
    };

    return <ContentTypeView {...settings} />;
  }

  return value.map(createItem);
};

const ViewInViewPreview = ({ model, view, path }) => {
  const [state, setState] = useState("initialFields");
  const [data, setData] = useState({});

  useEffect(() => {
    const { event } = model;
    let data;
    const sendEvent = async () => {
      data = await app().connection.emit({
        type: event,
        value: view.value,
        contentType: view.contentType,
      });
      setState("saveFields");
      setData(data);
      if (!view.value._id) view.setValue({ value: data[model.data2view] });
      else view.setValue({ path: "import", value: undefined });
    };

    const cancelEvent = async () => {
      data = await app().connection.emit({
        type: `${event}Cancel`,
        value: data.value,
        contentType: view.contentType,
        [model.data2view]: data[model.data2view],
        [model.data2list]: data[model.data2list],
      });
      setState("initialFields");
      setData({});
      if (!data.value._id) view.setValue({ value: {} });
    };

    const saveEvent = async () => {
      if (!data.value._id) await view.event("submit", { redirect: true });
      data = await app().connection.emit({
        type: `${event}Save`,
        value: data.value,
        contentType: view.contentType,
        [model.data2view]: data[model.data2view],
        [model.data2list]: data[model.data2list],
      });
      setState("initialFields");
      setData({});
    };

    view.on(event, sendEvent);
    view.on(`${event}Cancel`, cancelEvent);
    view.on(`${event}Save`, saveEvent);

    return () => {
      view.off(event, sendEvent);
      view.off(`${event}Cancel`, cancelEvent);
      view.off(`${event}Save`, saveEvent);
    };
  }, [model, view]);

  return (
    <Wrapper className={[model.className]}>
      <ContentTypeRender view={view} path={path} items={model[state]} />
      {state === "saveFields" && (
        <ViewListRender
          view={view}
          model={model}
          value={data[model.data2list]}
        />
      )}
    </Wrapper>
  );
};
export default ViewInViewPreview;
