import { Image } from "nystem-components";
import app from "nystem";

const ViewImageView = ({ model, view, path }) => {
  const insertVal = (val) =>
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "_language") val = app().settings.lang;
      else if (p1 === "_userid") val = app().session.user?._id;
      else if (p1 === "id") val = view.id;
      else if (p1 === "now") val = Date.now();
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        if (p1.startsWith("baseView.")) {
          p1 = p1.replace("baseView.", "");
          val = view.baseView.baseView.getValue(p1.replace("..", path));
        } else val = view.baseView.getValue(p1.replace("..", path));
      }
      return val || "";
    });

  return (
    <Image
      alt="logo"
      className={model.className}
      width={model.width}
      height={model.height}
      src={insertVal(
        model.fullPath ? model.filename : `/image/${model.filename}`
      )}
    />
  );
};
export default ViewImageView;
