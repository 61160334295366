import { useEffect, useState } from "react";

const DatabaseFilterByBaseSearchResult = ({ view, model }) => {
  const [baseFilter, setBaseFilter] = useState(false);

  useEffect(() => {
    const [from, to, divider] = model.map[0];
    const updateSearch = ({ data }) => {
      const filter = {};
      filter[to] = (data || []).map((item) => item[from]).join(divider || "");
      setBaseFilter(filter);
    };
    view.baseView.on("search", -100, updateSearch);
    return () => {
      view.baseView.off("search", updateSearch);
    };
  }, [model.map, view]);

  useEffect(() => {
    if (!baseFilter) return;

    const onSearch = (search) => ({ ...search, filter: baseFilter });

    view.on("setSearch", 10, onSearch);
    view.event("setSearch");
    return () => {
      view.off("setSearch", onSearch);
    };
  }, [baseFilter, view]);

  return null;
};

export default DatabaseFilterByBaseSearchResult;
